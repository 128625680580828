import { useState } from 'react';
import classNames from 'classnames/bind';

import styles from './EnterAuth.module.scss';

const cx = classNames.bind(styles);

const EnterAuth = ({ onSetAuth }) => {
  const [auth, setAuth] = useState({
    email: '',
    password: '',
  });

  const onConfirm = () => {
    if (auth.email === '') {
      alert('이메일을 입력해 주세요.');
      return;
    } else if (auth.password === '') {
      alert('비밀번호를 입력해 주세요.');
      return;
    }

    onSetAuth(auth);
  };

  const onChange = (e) => {
    setAuth({
      ...auth,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('fields')}>
        <input type="text" name="email" onChange={onChange} placeholder="이메일" className={cx('field')} />
        <input type="password" name="password" onChange={onChange} placeholder="비밀번호" className={cx('field')} />
      </div>
      <input type="button" onClick={onConfirm} value="확인" className={cx('button')} />
    </div>
  );
};

export default EnterAuth;
