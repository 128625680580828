import PageTitle from '../../../components/shared/PageTitle';
import S01 from './S01';
import S02 from './S02';
import S03 from './S03';
import S04 from './S04';
import Outro from '../../../components/product/Outro';

const Barrel = () => {
  return (
    <div>
      <PageTitle title="풀무원 퓨어" subtitle="믿을 수 있는 순수 미네랄워터" />
      <S01 />
      <S02 />
      <S03 />
      <S04 />
      {/* <S05 /> */}
      <Outro title={'순수 미네랄 워터, 풀무원 퓨어\n건강한 수분 섭취를 시작해 보세요!'} color="pure" />
    </div>
  );
};

export default Barrel;
