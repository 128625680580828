import React from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Report2023 = () => {
  return (
    <div className={cx('limiter')}>
      <div className={cx('table')}>
        <p className={cx('sub')}>전자공시 - 22기 결산 공고</p>
        <h3 className={cx('title')}>재무상태표</h3>
        <p className={cx('date')}>2024년 12월 31일 (단위: 원)</p>
        <div className={cx('scrollable')}>
          <div className={cx('head')}>
            <ul>
              <li style={{ flex: 1 }}>과목</li>
              <li style={{ flex: 1 }}>제 22(당) 기말</li>
              <li style={{ flex: 1 }}>제 21(전) 기말</li>
            </ul>
          </div>
          <div className={cx('body')}>
            <ul>
              <li>
                <ul>
                  <li className={cx('point')}>자산</li>
                  <li></li>
                  <li></li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유동자산</li>
                  <li>46,792,210,882</li>
                  <li>63,707,804,240</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>현금및현금성자산</li>
                  <li>4,580,023,149</li>
                  <li>9,521,938,289</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>매출채권및기타채권</li>
                  <li>11,568,985,814</li>
                  <li>6,836,407,410</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>재고자산</li>
                  <li>9,730,301,810</li>
                  <li>7,050,639,955</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타유동자산</li>
                  <li>258,054,541</li>
                  <li>261,099,076</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>단기투자자산</li>
                  <li>20,654,845,568</li>
                  <li>40,037,719,510</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>비유동자산</li>
                  <li>82,837,417,191</li>
                  <li>53,282,562,871</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>종속기업투자</li>
                  <li>25,402,950,983</li>
                  <li>-</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>장기매출채권및기타채권</li>
                  <li>965,587,162</li>
                  <li>892,207,524</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유형자산</li>
                  <li>46,096,165,832</li>
                  <li>42,983,849,834</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>무형자산</li>
                  <li>8,554,750,046</li>
                  <li>8,024,861,862</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>이연법인세자산</li>
                  <li>1,636,293,431</li>
                  <li>1,381,643,651</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타비유동자산</li>
                  <li>181,669,737</li>
                  <li>-</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>자산총계</li>
                  <li>129,629,628,073</li>
                  <li>116,990,367,111</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className={cx('point')}>부채및자본</li>
                  <li></li>
                  <li></li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유동부채</li>
                  <li>29,830,959,606</li>
                  <li>19,713,421,888</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>매입채무및기타채부</li>
                  <li>10,394,608,036</li>
                  <li>16,733,438,084</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>단기차입금</li>
                  <li>4,280,000,000</li>
                  <li>-</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유동성리스부채</li>
                  <li>638,472,831</li>
                  <li>818,314,677</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>당기법인세부채</li>
                  <li>1,159,294,955</li>
                  <li>782,144,276</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>파생금율부채</li>
                  <li>12,417,573,759</li>
                  <li>-</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타유동부채</li>
                  <li>807,656,771</li>
                  <li>1,097,231,665</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타유동충당부채</li>
                  <li>122,455,993</li>
                  <li>282,293,186</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타유동금융부채</li>
                  <li>10,897,261</li>
                  <li>-</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>비유동부채</li>
                  <li>8,196,855,050</li>
                  <li>10,438,453,466</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>장기차입금</li>
                  <li>-</li>
                  <li>4,280,000,000</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>장기리스부채</li>
                  <li>666,080538</li>
                  <li>343,599,142</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>순확정급여부채</li>
                  <li>7,415,992,190</li>
                  <li>5,652,496,078</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타비유동총당부채</li>
                  <li>114,782,322</li>
                  <li>162,358,246</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>부채총계</li>
                  <li>38,027,814,656</li>
                  <li>30,151,875,354</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>자본금</li>
                  <li>11,012,460,000</li>
                  <li>11,012,460,000</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타불입자본</li>
                  <li>52,407,766,754</li>
                  <li>52,407,766,754</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>이익잉여금</li>
                  <li>28,181,586,663</li>
                  <li>23,418,265,003</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>자본총계</li>
                  <li>91,601,813,417</li>
                  <li>86,838,491,757</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>부채및자본총계</li>
                  <li>129,629,628,073</li>
                  <li>116,990,367,111</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={cx('inner')}>
        <div className={cx('ann')}>
          <p>위와 같이 공고함</p>
          <ul>
            <li>2025년 3월 31일</li>
            <li>풀무원샘물 주식회사</li>
            <li>대표이사 백동옥</li>
          </ul>
        </div>
        <div className={cx('text')}>
          <p>
            감사의견 : 우리의 의견으로는 회사의 재무제표는 2024년 12월 31일 현재의 재무상태, 동일로 종료되는 보고기간의 재무성과 및
            현금흐름을 한국채택국제회계기준에 따라 중요성의 관점에서 공정하게 표시하고 있습니다.
            <span>삼일회계법인 대표이사 윤훈수</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Report2023;
